import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

const APIdomain = process.env.VUE_APP_API_DOMAIN || '';
const paymentsURL = `${APIdomain}/api/payment/`;
const notifyURL = `${APIdomain}/api/notify/`;
const personsURL = `${APIdomain}/api/person/`;
const proxiesURL = `${APIdomain}/api/proxy/`;
const loginURL = `${APIdomain}/api/login/`;
const cardURL = `${APIdomain}/api/card/`;
const smsURL = `${APIdomain}/api/sms/`;
const smsExtURL = `${APIdomain}/api/sms_ext/`;
const addPersonURL = `${APIdomain}/api/addperson/`;
const PersonsExtInfoURL = `${APIdomain}/api/getPersonsExtInfo/`; 
const CardsExtInfoURL = `${APIdomain}/api/getCardsExtInfo/`; 
const PaymentsExtInfoURL = `${APIdomain}/api/getPaymentsExtInfo/`; 
const getBidsPaymentsURL = `${APIdomain}/api/getBidsPayments/`; 


function appendProxyStr(proxies){
  proxies.forEach(obj => {
    obj.proxy_str = `${obj.proxy_type}//${obj.proxy_login}:${obj.proxy_password}@${obj.proxy_address}:${obj.proxy_port}`
  });

  return proxies
}

let store = new Vuex.Store({
  state: {
    payments: [],
    new_payments: [],
    new_payments_count: +localStorage.getItem('new_payments_count') || 0,
    new_sms: [],
    new_sms_count: +localStorage.getItem('new_sms_count') || 0,
    persons: [],
    cards: [],
    balances: [],
    proxies: [],
    status: '',
    token: localStorage.getItem('token') || '',
    user : JSON.parse(localStorage.getItem('user_data')) || '',
    sms: [],

    CardTypeItems: [
      { label: 'Банковская карта', value: 'card' },
      { label: 'Qiwi', value: 'qiwi' },
    ]
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    authUserData: state => state.user,
    CARD_TYPES(state){
      return state.CardTypeItems
    },
    PAYMENTS(state){
      return state.payments;
    },
    NEW_PAYMENTS(state){
      return state.new_payments;
    },
    NEW_PAYMENTS_COUNT(state){
      return state.new_payments_count;
    },
    NEW_SMS(state){
      return state.new_sms;
    },
    NEW_SMS_COUNT(state){
      return state.new_sms_count;
    },
    PERSONS(state){
      return state.persons;
    },
    PROXIES(state){
      return state.proxies;
    },
    BALANCES(state){
      return state.balances;
    },
    SMS(state){
      return state.sms;
    },
  },
  mutations: {
    SET_NOTIFY_TO_STATE: (state, {obj, key, count}) => {
      if ('results' in obj && 'count' in obj)
      {
        state['new_'+key] = obj.results
        state['new_'+key+'_count'] = (count ? count : obj.count)
  
        localStorage.setItem('new_'+key+'_count', state['new_'+key+'_count'])
      }
    },
    SET_PAYMENTS_TO_STATE: (state, payments) => {
      state.payments = payments
    },
    SET_BIDS_TO_STATE: (state, bids) => {
      state.bids = bids
    },
    SET_PERSONS_TO_STATE: (state, persons) => {
      state.persons = persons
    },
    SET_CARDS_TO_STATE: (state, cards) => {
      state.cards = cards
    },
    SET_PROXY_SUCCESS: (state, proxy) => {
      if (proxy.itemIndex > -1)
      {
        console.log('edit')
        Object.assign(state.proxies[proxy.itemIndex], proxy.data)
      } else {
        state.proxies.push(proxy.data)
      }
    },
    DELETE_PROXY_SUCCESS: (state, itemIndex) => {
      state.proxies.splice(itemIndex, 1)
    },
    SET_BALANCE_TO_STATE: (state, balances) => {
      state.balances = balances
    },
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, auth_data){
      state.status = 'success'
      state.token = auth_data.token
      state.user = auth_data.user_data
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
      state.user = ''
    },
    POST_ADD_PERSON_SUCCESS(state, personItem){
      state.persons.push(personItem)
    },
    DELETE_PERSON_SUCCESS(state, personIndex){
      state.persons.splice(personIndex, 1)
    },
    DELETE_CARD_SUCCESS(state, personIndex){
      state.cards.splice(personIndex, 1)
    },
    GET_PROXY_SUCCESS(state, proxies){
      state.proxies = appendProxyStr(proxies)
    },
    SET_PERSON_SUCCESS(state, personItem, personIndex){
      state.persons.splice(personIndex, 1, personItem)
    },
    SET_SMS_TO_STATE(state, sms){
      state.sms = sms
    },
  },
  actions: {
    login({commit}, user_data){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: loginURL, data: user_data, method: 'POST' })
        .then(resp => {
          const token = `Token ${resp.data.token}`
          const user_data = resp.data.user_data
          localStorage.setItem('token', token)
          localStorage.setItem('user_data', JSON.stringify(user_data))
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', {token, user_data})
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user_data')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user_data')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    GET_PAYMENTS_FROM_API({commit}, {params={}}){
      return axios(PaymentsExtInfoURL, {
        method: 'GET',
        params: params
      })
      .then((payments) => {
          commit('SET_PAYMENTS_TO_STATE', payments.data);
          return payments;
        })
      .catch((error) => {
          console.log(error);
          return error;
        })
    },
    GET_NOTIFY_API({commit}, {params={}}){
      return axios(notifyURL, {
        method: 'GET',
        params: params
      })
      .then((notify) => {
        for (var key in notify.data) {
            commit('SET_NOTIFY_TO_STATE', {obj: notify.data[key], key: key, count: this.getters["NEW_"+key.toUpperCase()+"_COUNT"]+notify.data[key].count});
        }
          return notify;
        })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    GET_BIDS_FROM_API({commit}, {params={}}){
      return axios(getBidsPaymentsURL, {
        method: 'GET',
        params: params
      })
      .then((bids) => {
          commit('SET_BIDS_TO_STATE', bids.data);
          return bids;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    GET_PERSONS_FROM_API({commit}){
      return axios(personsURL, {
        method: 'GET'
      })
      .then((persons) => {
          commit('SET_PERSONS_TO_STATE', persons.data.data);
          return persons;
        })
      .catch((error) => {
          console.log(error);
          return error;
        })
    },
    GET_PERSONS_EXT_FROM_API({commit}, {params={}}){
      return axios(PersonsExtInfoURL, {
        method: 'GET',
        params: params || {},
      })
      .then((persons) => {
          commit('SET_PERSONS_TO_STATE', persons.data.data);
          return persons;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    GET_CARDS_FROM_API({commit}, {params={}}){
      return axios(CardsExtInfoURL, {
        method: 'GET',
        params: params || {},
      })
      .then((cards) => {
          commit('SET_CARDS_TO_STATE', cards.data.data);
          return cards;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    GET_PROXY_FROM_API({commit}, {data={}}){
      return axios(proxiesURL, {
        method: 'GET',
        data: data || {},
      })
      .then((resp) => {
          commit('GET_PROXY_SUCCESS', resp.data);
          return resp;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    SET_PROXY_API({commit}, {data={}, proxy_id, itemIndex}){
      let url = proxiesURL
      let method = 'POST'
      if (proxy_id && proxy_id!='') {
        url = `${proxiesURL}${proxy_id}/`
        method = 'PUT'
      }
      return axios(url, {
        method: method,
        data: data || {},
      })
      .then((resp) => {
        let data = resp.data
          commit('SET_PROXY_SUCCESS', {data, itemIndex});
          return resp;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    DELETE_PROXY_API({commit}, {data={}, proxy_id, itemIndex}){
      return axios(`${proxiesURL}${proxy_id}/`, {
        method: 'DELETE',
        data: data || {},
      })
      .then((resp) => {
          commit('DELETE_PROXY_SUCCESS', itemIndex);
          return resp;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    POST_ADD_PERSON_API({commit}, {data={}}){
      return axios(addPersonURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data,
      })
      .then(resp => {
        commit('POST_ADD_PERSON_SUCCESS', resp.data);
        return resp
      })
      .catch((error) => {
          console.log(error);
          return error
      })
    },
    SET_PERSON_PROP_API({commit}, {data={}, person_id, itemIndex}){
      return axios(`${personsURL}${person_id}/set_prop/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data,
      })
      .then((resp) => {
          commit('SET_PERSON_SUCCESS', resp.data, itemIndex);
          return resp;
        })
      .catch((error) => {
          console.log(error);
          return error;
        })
    },
    GET_SMS_FROM_API({commit}, {params={}}){
      return axios(smsExtURL, {
        method: 'GET',
        params: params,
      })
      .then((resp) => {
          commit('SET_SMS_TO_STATE', resp.data);
          return resp;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    SET_PERSON_API({commit}, {data={}, person_id}){
      return axios(`${personsURL}${person_id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data,
      })
      .then((resp) => {
          //commit('SET_PERSON_TO_STATE', resp.data);
          return resp;
      })
      .catch((error) => {
          console.log(error);
          return error;
      })
    },
    DELETE_PERSON_API({commit}, {data={}, person_id, itemIndex}){
      return axios(`${personsURL}${person_id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data,
      })
      .then(resp => {
        commit('DELETE_PERSON_SUCCESS', itemIndex);
        return resp
      })
      .catch((error) => {
        console.log(error);
        return error
      })
    },
    DELETE_CARD_API({commit}, {data={}, card_id, itemIndex}){
      return axios(`${cardURL}${card_id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data,
      })
      .then(resp => {
        commit('DELETE_CARD_SUCCESS', itemIndex);
        return resp
      })
      .catch((error) => {
        console.log(error);
        return error
      })
    },
  },
  modules: {
  }
})

export default store